<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">Affected Users<br>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to types of personnel list"
              @click="openNotificationContent"
            ><i data-feather="send" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Senior call settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div
          v-if="errors != ''"
          class="alert alert-danger mt-1 p-1"
        >
          {{ errors }}
        </div>
        <div class="row">
          <form
            id="edit-form"
            action=""
          >
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="cart-title">
                    <a data-action="collapse">Users</a>
                  </h3>
                  <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li>
                        <a data-action="collapse"><i data-feather="chevron-down" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-content collapse show">
                  <div class="card-body">
                    <div class="table-responsive-sm">
                      <table class="table table-striped table-hover">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Host institution</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="user, index in filteredUsers"
                            :key="index"
                          >
                            <td>{{ user.name }}</td>
                            <td>{{ user.host_name }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-secondary"
            @click="$router.push({name: 'admin.erc.index'})"
          >
            <i data-feather="chevron-left" />
            <span class="ms-25 align-middle">Back to index</span>
          </button>
        </div>

      </div>
    </div>

    <ItemInfoSheet>
      <template #info-sheet-title>
        Notification content
      </template>
      <template #info-sheet>
        <template> <!-- /* SHOWING SECTION */ -->
          <div class="offcanvas-body">
            <h6>A notification will be sent to the affected ICREAs</h6>
            <div class="mb-1">
              <label
                for=""
                class="form-label mt-2"
              >Notification text</label>
              <quill-editor
                v-model="message"
              />
            </div>
          </div>
          <div class="offcanvas-footer mt-auto">
            <button
              type="button"
              class="btn btn-primary mb-1 w-100"
            >
              <span class="me-25 align-middle">Send</span>
              <i data-feather="send" />
            </button>
          </div>
        </template>
      </template>
    </ItemInfoSheet>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ItemInfoSheet from '../../partials/offcanvas/ItemInfoSheet.vue'

export default {
  components: {
    ItemInfoSheet,
  },
  data() {
    return {
      sending: false,
      errors: '',
      message: '',
      filteredUsers: [],
    }
  },
  computed: {
    ...mapGetters({
      affectedUsers: 'erc/affectedUsers',
    }),
  },
  mounted() {
    this.filteredUsers = this.affectedUsers.filter((obj, index, self) => index === self.findIndex(o => (
      o.name === obj.name && o.id === obj.id
    )))
  },
  methods: {
    async save() {
      this.sending = true

      this.$router.push({ name: 'admin.erc.index' })

      this.sending = false
    },
    openNotificationContent() {
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
  },

}
</script>
